import { Component, OnInit, Input } from '@angular/core';
import {LangService} from "../../../core/lang/lang.service";

@Component({
  selector: 'app-page-title',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss']
})
export class PagetitleComponent implements OnInit {

  @Input() breadcrumbItemsList;
  @Input() title: string;

  constructor(public langService:LangService) { }


  public  GetWordText(key:any){
    return this.langService.GetWord(key);

  }
  ngOnInit() {
  }

}
