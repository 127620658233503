import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Language {

  lang_words: Array<any>;

  constructor() {
    this.lang_words = [
      {text_key: "_", text_ar: " ", text_en: " "}, {
        text_key: "home",
        text_ar: "الرئيسية",
        text_en: "Home"
      }, {
        text_key: "login_content",
        text_ar: "سجل دخول الى لوحة التحكم باستخدام البريد الالكتروني وكلمة المرور ",
        text_en: " Log in to the control panel using your email and password "
      },
      {text_key: "login", text_ar: " دخول ", text_en: " Login "},
      {text_key: "password",text_ar: " كلمة المرور ",text_en: " Password "},
      {text_key: "email", text_ar: "البريد الالكتروني", text_en: " Email "},
      {text_key: "remember_me", text_ar: "تذكرني", text_en: "Remember me"},
      {text_key: "change_lang", text_ar: "تغيير اللغة", text_en: "Change Language"},
      {text_key: "welcome", text_ar: "مرحبا", text_en: "Welcome"},
      {text_key: "welcome_text", text_ar: "مرحبا بك نتمني لك يوم عمل جيد", text_en: "Welcome, we wish you a good working day"},
      {text_key: "copy_rights", text_ar: "جميع الحقوق محفوظة", text_en: "All rights reserved"},
      {text_key: "company_name", text_ar: "NearYou", text_en: "NearYou"},
      {text_key: "login_information_not_correct", text_ar: "معلومات الدخول غير صحيحة", text_en: "Incorrect login information"},
      {text_key: "dashboard", text_ar: "لوحة التحكم", text_en: "Dashboard"},
      {text_key: "sliders", text_ar: "سلايدر", text_en: "Sliders"},
      {text_key: "offers", text_ar: "العروض", text_en: "Offers"},
      {text_key: "news", text_ar: "الأخبار", text_en: "News"},
      {text_key: "events", text_ar: "الأحداث", text_en: "Events"},
      {text_key: "projects", text_ar: "المشروعات", text_en: "Projects"},
      {text_key: "project_units", text_ar: "وحدات المشروعات ", text_en: "Project Unites"},
      {text_key: "service_providers", text_ar: "مقدمي الخدمات", text_en: "Service Providers"},
      {text_key: "services", text_ar: "الخدمات", text_en: "Services"},
      {text_key: "services_request", text_ar: "طلبات الخدمات", text_en: "Services Requests"},
      {text_key: "installments", text_ar: "الاقساط", text_en: "Installments"},
      {text_key: "transactions", text_ar: "العمليات المالية", text_en: "Transactions"},
      {text_key: "finance", text_ar: "الحسابات", text_en: "Finance"},
      {text_key: "users", text_ar: "المستخدمين", text_en: "Users"},
      {text_key: "clients", text_ar: "العملاء", text_en: "Clients"},
      {text_key: "notifications", text_ar: "الأشعارات", text_en: "Notifications"},
      {text_key: "owners_notifications", text_ar: "إشعارات الملاك", text_en: "Owners Notifications"},
      {text_key: "project_updates", text_ar: "اشعارات المشروعات", text_en: "Projects Updates"},
      {text_key: "admins_log", text_ar: "سجلات المستخدمين", text_en: "Admins Log"},
      {text_key: "adminslog", text_ar: "سجلات المستخدمين", text_en: "Admins Log"},
      {text_key: "cities", text_ar: "المدن", text_en: "Cities"},
      {text_key: "unit_types", text_ar: "أنواع الوحدات", text_en: "Unit Types"},
      {text_key: "projects_status", text_ar: "حالات المشروعات", text_en: "Projects Status"},
      {text_key: "settings", text_ar: "الاعدادات", text_en: "Settings"},
      {text_key: "payment_methods", text_ar: "وسائل الدفع", text_en: "Payments Methods"},
      {text_key: "vehicles_brands", text_ar: "أنواع السيارات", text_en: "Vehicles Brands"},
      {text_key: "configuration", text_ar: "الأعدادات", text_en: "Configuration"},
      {text_key: "all", text_ar: "الجميع", text_en: "All"},
      {text_key: "deleted", text_ar: "محذوف", text_en: "Deleted"},
      {text_key: "upcoming", text_ar: "بعد", text_en: "Upcoming"},
      {text_key: "exportImport", text_ar: "تصدير وإستيراد", text_en: "Export Import"},
      {text_key: "name", text_ar: "الاسم", text_en: "Name"},
      {text_key: "email", text_ar: "البريد الألكتروني", text_en: "Email"},
      {text_key: "mobile", text_ar: "رقم الموبايل", text_en: "Mobile"},
      {text_key: "date", text_ar: "التاريخ", text_en: "Date"},
      {text_key: "start_date", text_ar: "تاريخ البدء", text_en: "Start Date"},
      {text_key: "end_date", text_ar: "تاريخ النهاية", text_en: "End Date"},
      {text_key: "title_ar", text_ar: "العنوان AR", text_en: "Title AR"},
      {text_key: "title_en", text_ar: "العنوان  EN", text_en: "Title EN"},
      {text_key: "content_ar", text_ar: "المحتوي AR", text_en: "Content AR"},
      {text_key: "content_en", text_ar: "المحتوي  EN", text_en: "Content EN"},
      {text_key: "description_ar", text_ar: "الوصف  AR", text_en: "Description AR"},
      {text_key: "description_en", text_ar: "الوصف EN", text_en: "Description EN"},
      {text_key: "delete", text_ar: "حذف", text_en: "Delete"},
      {text_key: "add_new", text_ar: "إضافة جديد", text_en: "Add New"},
      {text_key: "search", text_ar: "بحث", text_en: "Search"},
      {text_key: "save", text_ar: "حفظ", text_en: "Save"},
      {text_key: "save_update", text_ar: "حفظ التغييرات", text_en: "Save Updates"},
      {text_key: "admins", text_ar: "الادارة والعاملين", text_en: "Admins&Employers"},
      {text_key: "admins_levels", text_ar: "اقسام الادارة", text_en: "Admins Levels"},
      {text_key: "admins_access", text_ar: "صلاحيات الوصول", text_en: "Admins Access "},
      {text_key: "unit_resale_request", text_ar: "طلبات اعادة بيع الوحدات", text_en: "Units Resale Requests"},
      {text_key: "name_ar", text_ar: "الاسم AR ", text_en: "Name AR"},
      {text_key: "name_en", text_ar: "الاسم EN", text_en: "Name EN"},
      {text_key: "actions", text_ar: " الاجراءات", text_en: "Actions"},
      {text_key: "lat_lng", text_ar: "الاحداثيات", text_en: "Map Lat,Lng"},
      {text_key: "show", text_ar: "عرض", text_en: "Show"},
      {text_key: "entries", text_ar: "مدخل", text_en: "Entries"},
      {text_key: "Edit", text_ar: "تعديل", text_en: "Edit"},
      {text_key: "update", text_ar: "تعديل", text_en: "Update"},
      {text_key: "icon", text_ar: "صورة رمزية ", text_en: "Icon"},
      {text_key: "deleteConfirm", text_ar: " هل انت متأكد من  الحذف ؟", text_en: "Are you sure delete this item ? "},
      {text_key: "yesDeleteIt", text_ar: "نعم إحذف ذلك ", text_en: "Yes delete it "},
      {text_key: "to", text_en: "To", text_ar: "الي"},
      {text_key: "of", text_en: "Of", text_ar: "من"},
      {text_key: "Showing", text_en: "Showing", text_ar: "معروض"},
      {text_key: "restore", text_ar: "إستعادة", text_en: "Restore"},
      {text_key: "restoreConfirm", text_ar: "هل انت متأكد من إستعادة هذا ؟", text_en: "Are you sure restore this item ?"},
      {text_key: "yes_restore_it", text_ar: "نعم", text_en: "Yes"},
      {text_key: "success", text_ar: "عملية ناجحة", text_en: "Success"},
      {text_key: "add_successfully", text_ar: "عملية إضافة ناجحة", text_en: "Added Successfully"},
      {text_key: "deleted_successfully", text_ar: "تم الحذف بنجاح", text_en: "Deleted Successfully"},
      {text_key: "restored_successfully", text_ar: "تم الاستعادة بنجاح", text_en: "Restored Successfully"},
      {text_key: "alert", text_ar: "إنتبة", text_en: "Alert"},
      {text_key: "photo", text_ar: "صورة", text_en: "Photo"},
      {text_key: "project_id", text_ar: "المشروع", text_en: "Project"},
      {text_key: "unit_id", text_ar: "الوحدة", text_en: "Unit"},
      {text_key: "select", text_ar: "اختر", text_en: "Select"},
      {text_key: "please_enter", text_ar: "فضلا ادخل", text_en: "Please Enter "},
      {text_key: "photos", text_ar: "الصور", text_en: "Photos"},
      {text_key: "address_ar", text_ar: "العنوان AR", text_en: "Address AR"},
      {text_key: "address_en", text_ar: "العنوان EN", text_en: "Address EN"},
      {text_key: "project_status", text_ar: "حالة المشروع", text_en: "Project Status"},
      {text_key: "city_id", text_ar: "المدينة", text_en: "City"},
      {text_key: "export", text_ar: "تصدير", text_en: "Export"},
      {text_key: "error", text_ar: "خطأ", text_en: "Error"},
      {text_key: "please_enter_required_inputs", text_ar: "فضلا أدخل جميع الحقول الألزامية", text_en: "Please enter required inputs"},
      {text_key: "projects_gallery", text_ar: "مركز الميديا", text_en: "Projects gallery"},
      {text_key: "gallery_file", text_ar: "الملف", text_en: "File"},
      {text_key: "file_type", text_ar: "نوع الملف", text_en: "File Type"},
      {text_key: "projects_timeline", text_ar: "الجدول الزمني ", text_en: "Projects Timeline"},
      {text_key: "current_status", text_ar: "الحالة", text_en: "Current Status"},
      {text_key: "blocked", text_ar: "حظر", text_en: "Blocked"},
      {text_key: "user_id", text_ar: "المستخدم", text_en: "User"},
      {text_key: "user_level", text_ar: "مستوي الوصول", text_en: "User Level"},
      {text_key: "unit_price", text_ar: "سعر الوحدة", text_en: "Unit Price"},
      {text_key: "unit_price_table", text_ar: "جدول أسعار الوحدات", text_en: "Unit price"},
      {text_key: "down_payment", text_ar: "دفعة تعاقد", text_en: "Down payment"},
      {text_key: "monthly_installment", text_ar: "القسط الشهري", text_en: "Monthly installment"},
      {text_key: "quarter_installment", text_ar: "القسط الربع سنوي", text_en: "Quarter installment"},
      {text_key: "yearly_installment", text_ar: "القسط السنوي", text_en: "Yearly installment"},
      {text_key: "service_installment", text_ar: "رسوم الخدمات  ", text_en: "Service installment"},
      {text_key: "price_valid_to", text_ar: "هذا السعر صالح حتي ", text_en: "Price Valid to date "},
      {text_key: "waiting", text_ar: "بالانتظار", text_en: "Waiting"},
      {text_key: "confirmed", text_ar: "مؤكد", text_en: "Confirmed"},
      {text_key: "completed", text_ar: "مكتمل", text_en: "Completed"},
      {text_key: "canceled", text_ar: "ملغي", text_en: "Canceled"},
      {text_key: "overdo", text_ar: "مر عليه", text_en: "Overdo"},
      {text_key: "plus", text_ar: "إضافة", text_en: "Plus"},
      {text_key: "minus", text_ar: "تنزيل", text_en: "Minus"},
      {text_key: "today", text_ar: "اليوم", text_en: "Today"},
      {text_key: "send", text_ar: "ارسال", text_en: "Send"},
      {text_key: "history", text_ar: "سجل الارسال", text_en: "History"},
      {text_key: "active", text_ar: "تفعيل", text_en: "Active"},
      {text_key: "block", text_ar: "حظر", text_en: "Block"},
      {text_key: "yes", text_ar: "نعم", text_en: "Yes"},
      {text_key: "no", text_ar: "لا", text_en: "No"},
      {text_key: "user_lang", text_ar: "لغة الاستخدام", text_en: "User Lang"},
      {text_key: "parent_account", text_ar: "حساب رئيسي", text_en: "Parent Account"},
      {text_key: "dark_mode", text_ar: "ثمة التطبيق", text_en: "App Theme"},
      {text_key: "darkmode", text_ar: "ثمة التطبيق", text_en: "App Theme"},
      {text_key: "installment_table", text_ar: "أقساط العملاء", text_en: "Clients Installments"},
      {text_key: "client_transactions", text_ar: "معاملات العملاء", text_en: "Clients Transactions"},
      {text_key: "client_id", text_ar: "العميل", text_en: "Client"},
      {text_key: "payment_method", text_ar: "وسيلة الدفع", text_en: "Payment method"},
      {text_key: "trasaction_amount", text_ar: "مبلغ الحركة", text_en: "Transaction Amount"},
      {text_key: "trasaction_type", text_ar: "نوع الحركة", text_en: "Transaction Type"},
      {text_key: "installment_alert", text_ar: "تنبيه الاقساط", text_en: "Installment Alert"},
      {text_key: "installment_alert_days", text_ar: "تنبيه الاقساط قبل", text_en: "Installment Alert days"},
      {text_key: "installment_alert_repeated", text_ar: "تكرار تنبية الاقساط", text_en: "Installment Alert Repeated"},
      {text_key: "project_update_notifications", text_ar: "اشعار بتحديث حالة المشروعات", text_en: "Project updates notifications"},
      {text_key: "user_photo", text_ar: "صورة المستخدم", text_en: "User Photo"},
      {text_key: "admins_level", text_ar: "مستوي الوصول", text_en: "Admin level"},
      {text_key: "path", text_ar: "المسار", text_en: "Path"},
      {text_key: "path_log", text_ar: "المسار", text_en: "Path"},
      {text_key: "edit", text_ar: "التعديل", text_en: "Edit"},
      {text_key: "add", text_ar: "الأضافة", text_en: "Add"},
      {text_key: "table_name", text_ar: "اسم الجدول", text_en: "Table Name"},
      {text_key: "path_action", text_ar: "الأجراء", text_en: "User Action"},
      {text_key: "item_id", text_ar: "المعرف", text_en: "Item ID"},
      {text_key: "log_date_time", text_ar: "تاريخ ووقت السجل", text_en: "Log date and time "},
      {text_key: "log_ip_address", text_ar: "IP", text_en: "IP"},
      {text_key: "star_log", text_ar: "تتميز السجل", text_en: "Mark Log"},
      {text_key: "before_action", text_ar: "قبل الاجراء", text_en: "Before Action"},
      {text_key: "after_action", text_ar: "بعد الاجراء", text_en: "After Action"},
      {text_key: "createdAt", text_ar: "تاريخ الأنشاء", text_en: "Created At"},
      {text_key: "updatedAt", text_ar: "تاريخ التحديث", text_en: "Updated At"},
      {text_key: "messages", text_ar: "الرسائل", text_en: "Messages"},
      {text_key: "request_messages", text_ar: "طلبات التواصل", text_en: "Request Messages"},
      {text_key: "client_units", text_ar: "وحدات العملاء", text_en: "Clients Units "},
      {text_key: "price", text_ar: "Price", text_en: "Price"},
      {text_key: "details", text_ar: "التفاصيل", text_en: "Details"},
      {text_key: "request_status", text_ar: "حالة الطلب", text_en: "Request status"},
      {text_key: "attachments", text_ar: "المرفقات", text_en: "Attachments"},
      {text_key: "installment_price", text_ar: "المبلغ", text_en: "Amount"},
      {text_key: "do_date", text_ar: "تاريخ الاستحقاق", text_en: "Do Date"},
      {text_key: "payment_status", text_ar: "حالة الدفع", text_en: "payment status"},
      {text_key: "days", text_ar: "يوم", text_en: "days"},
      {text_key: "egp", text_ar: "ج.م", text_en: "Egp"},
      {text_key: "paid", text_ar: "مدفوع", text_en: "Paid"},
      {text_key: "payment_confirm", text_ar: "تأكيد الدفع", text_en: "Payment confirm"},
      {text_key: "payment_confirm_msg", text_ar: "هل انت متأكد من العملية ؟", text_en: "Are you sure pay this installment?"},
      {text_key: "payment_unsuccessfully", text_ar: "عملية دفع غير ناجحة", text_en: "Payment Unsuccessfully"},
      {text_key: "bulk_insert", text_ar: "ادخال جماعي", text_en: "Bulk Insert"},
      {text_key: "import", text_ar: "إستيراد", text_en: "Import"},
      {text_key: "download_template", text_ar: "تحميل النموذج", text_en: "Download Template"},
      {text_key:"exportImport",text_ar: "إستيراد وتصدير البيانات", text_en: "Export Import Data "},
      {text_key:"no_data_to_export",text_ar: "لا يوجد أي بيانات للتصدير", text_en: "No have any data to export "},
      {text_key:"import_note_2",text_ar: "الحقل الذي يتحتوي على كلمة empty يجب تركة فارغاً", text_en: "The field containing the word empty must be left blank"},
      {text_key:"export_importing_log",text_ar: "تنزيل تقرير الاستيراد", text_en: "Download Importing Log"},
      {
        text_key: "import",
        text_ar: "إستيراد البيانات",
        text_en: "Import Data "
      }, {text_key: "export", text_ar: "تصدير البيانات ", text_en: " Export Data "}, {
        text_key: "import_note",
        text_ar: " قم بتحميل القالب ثم اضاف البيانات وقم باستيرادها دفعة واحدة ",
        text_en: " Download the template, add the data, and import it in one go "
      }, {
        text_key: "import_note_file",
        text_ar: " يجب عليك الالتزام بتنسيق القالب وعدم تغيير العناوين او اسم الشيت فقط قم بتغيير البيانات ",
        text_en: " You must adhere to the format of the template and not change the addresses or the name of the sheet, just change the data "
      }, {
        text_key: "select_file_and_click_upload",
        text_ar: " فضلا اختر ملف الاكسل ثم اضغط على زر استيراد ",
        text_en: " choose excel file and click import Data button "
      }, {text_key: "uploadfile", text_ar: " استيراد من ", text_en: " Import From "}, {
        text_key: "downloadFile",
        text_ar: " تحميل القالب ",
        text_en: " Download Template "
      }, {text_key: "download", text_ar: " تحميل ", text_en: " Download "}, {
        text_key: "export_note",
        text_ar: "تصدير جميع بيانات الجدول المخزنة بقاعدة البيانات ",
        text_en: " Export all table data stored in the database "
      }, {
        text_key: "export_data_to",
        text_ar: " تصدير البيانات الى ",
        text_en: " export data to "
      }, {
        text_key: "click_and_waiting_server_working",
        text_ar: " اضغط على تصدير البيانات وانتظر قليلا حتي يجهز الخادم البيانات ",
        text_en: " Click on Export Data and wait a while for the server to prepare the data "
      },
      {text_key: "gates", text_ar: "البوابات", text_en: "Gates"},
      {text_key: "gates_emp", text_ar: "عاملين الوبابات", text_en: "Gates Employers"},
      {text_key: "emp_code", text_ar: "كود العامل", text_en: "Employer Code"},
      {text_key: "gate", text_ar: "البوابة", text_en: "Gate"},
      {text_key: "payment_requests", text_ar: "اخطارات السداد", text_en: "Payment Requests"},
      {text_key: "installment_id", text_ar: "القسط", text_en: "installment"},
      {text_key: "amount", text_ar: "المبلغ", text_en: "Amount"},
      {text_key: "method_id", text_ar: "وسيلة الدفع", text_en: "Payment Method"},
      {text_key: "status", text_ar: "الحالة", text_en: "Status"},
      {text_key: "user_confirm", text_ar: "المسئول", text_en: "User confirm"},
      {text_key: "project_code", text_ar: "كود المشروع", text_en: "Project Code"},
      {text_key: "unit_code", text_ar: "كود الوحدة", text_en: "Unit Code"},
      {text_key: "unit_no", text_ar: "رقم الوحدة", text_en: "Unit No"},
      {text_key: "pay_years", text_ar: "سنوات السداد", text_en: "Payment years"},
      {text_key: "message", text_ar: "الرسالة", text_en: "Message"},
      {text_key: "title", text_ar: "العنوان", text_en: "Title"},
      {text_key: "read_status", text_ar: "قرأة", text_en: "Read"},
      {text_key: "messages_request", text_ar: "طلبات المراسلة", text_en: "Message Request"},
      {text_key: "meeting_booking", text_ar: "حجز المواعيد", text_en: "Meeting Booking"},
      {text_key: "booking_date", text_ar: "تاريخ الحجز", text_en: "Booking date"},
      {text_key: "unit_price_id", text_ar: "نمط التسعير", text_en: "Price schema"},
      {text_key: "soldout", text_ar: "اكتمل البيع", text_en: "Sold out"},
      {text_key: "knowledge", text_ar: "المعرفة", text_en: "knowledge"},
      {text_key: "knowledge_departments", text_ar: "الاقسام", text_en: "Departments"},
      {text_key: "department_id", text_ar: "القسم", text_en: "Department"},
      {text_key: "db_backup", text_ar: "نسخ احتياطي", text_en: "DB backup"},
      {text_key: "app", text_ar: "التطبيق", text_en: "App"},
      {text_key: "theme", text_ar: "ثمة التطبيق", text_en: "Theme"},
      {text_key: "data_set", text_ar: "تهيئة البيانات", text_en: "Data set"},
      {text_key: "client_code", text_ar: "كود العميل", text_en: "Client Code"},
      {text_key: "mobil2", text_ar: "موبايل 2", text_en: "mobile 2"},
      {text_key: "mobil3", text_ar: "موبايل 3", text_en: "mobile 3"},
      {text_key: "mobil4", text_ar: "موبايل 4", text_en: "mobile 4"},
      {text_key: "partially", text_ar: "مسدد جزئي", text_en: "Partially"},
      {text_key: "o_check", text_ar: "Deposited check", text_en: "شيك مودع"},
      {text_key: "c_check", text_ar: "Collected check", text_en: "شيك محصل"},
      {text_key: "youtube_gallery", text_ar: "يوتيوب ", text_en: "Youtube gallery"},
      {text_key: "icons_link", text_en: "Icons Urls", text_ar: "روابط"},
      {text_key: "text_ar", text_ar: "نص ar", text_en: "Text Ar"},
      {text_key: "text_en", text_ar: "نص en", text_en: "Text En"},
      {text_key: "event", text_ar: "حدث", text_en: "Event"},
      {text_key: "units", text_ar: "الوحدات", text_en: "Unites "},
      {text_key: "installment_paid", text_ar: "الاقساط المسددة", text_en: "Installment paid"},
      {text_key: "installment_waiting", text_ar: "الاقساط الغير مسددة", text_en: "Installment waiting"},
      {text_key: "installment_total", text_ar: "اجمال المبيعات", text_en: "Installment total"},
      {text_key: "erp_import", text_ar: "استبراد ERP", text_en: "ERP Import"},
      {text_key: "erpImport", text_ar: "استبراد ERP", text_en: "ERP Import"},
      {text_key: "youtube_url", text_ar: "رابط اليوتيوب", text_en: "Youtube URL"},
      {text_key: "erp_import_note", text_ar: "يجب مراعاة ان يكون اول صف فى الاكسل هو نفس الصورة التالية", text_en: "يجب مراعاة ان يكون اول صف فى الاكسل هو نفس الصورة التالية"},
      {text_key: "erp_import_note_2", text_ar: "", text_en: ""},
      {text_key: "downpayment", text_ar: "دفعة مقدمة", text_en: "Down Payment"},
      {text_key: "unit_space", text_ar: "مساحة الوحدة", text_en: "Unit Space"},
      {text_key: "unit_m_price", text_ar: "سعر متر الوحدة", text_en: "Unit Meter Price"},
      {text_key: "garden_space", text_ar: "مساحة الحديقة", text_en: "Garden Space"},
      {text_key: "garden_m_price", text_ar: "سعر متر الحديقة", text_en: "Garden Meter Price"},
      {text_key: "discount", text_ar: "الخصم", text_en: "Discount"},
      {text_key: "payment_years", text_ar: "سنوات السداد", text_en: "Payment years "},
      {text_key: "first_installment", text_ar: "موعد اول قسط", text_en: "First installment date"},
      {text_key: "payment_plan", text_ar: "خطة مدفوعات", text_en: "Payment plan"},
      {text_key: "grag_price", text_ar: "سعر الجراج", text_en: "Garage Price"},
      {text_key: "view_plan", text_ar: "عرض الخطة", text_en: "View Plan"},
      {text_key: "download_pdf", text_ar: "تحميل PDF", text_en: "Download PDF"},
      {text_key: "agents", text_ar: "الوسطاءالعقاريين", text_en: "Agents"},
      {text_key: "business", text_ar: "الانشطة التجارية", text_en: "Business"},
      {text_key: "providers", text_ar: "مقدمي الخدمة", text_en: "Providers"},
      {text_key: "user_devices", text_ar: "اجهزة المستخدمين", text_en: "Users Devices"},
      {text_key: "properties", text_ar: "العقارات", text_en: "Properties"},
      {text_key: "auctions", text_ar: "المزادات", text_en: "Auctions"},
      {text_key: "requests", text_ar: "مطلوب", text_en: "Requests"},
      {text_key: "waitingComments", text_ar: "تعليقات بالأنتظار", text_en: "Waiting Comments"},
      {text_key: "waitingCommentsReport", text_ar: "بلاغات تعليقات بالانتظار", text_en: "Waiting Comments Report"},
      {text_key: "waitingBusinessReport", text_ar: "بلاغات الانشطة بالانتظار", text_en: "Waiting Business Report"},
      {text_key: "waitingUserReport", text_ar: "بلاغات مستخدمين بالانتظار", text_en: "Waiting User Report"},
      {text_key: "promotions", text_ar: "العروض", text_en: "Promotions"},
      {text_key: "used_promotions", text_ar: "عروض مستخدمة", text_en: "Used promotions"},
      {text_key: "unpaid_invoices", text_ar: "فواتير غير مسددة", text_en: "Unpaid Invoices"},
      {text_key: "paid_invoices", text_ar: "فواتير مسددة", text_en: "Paid Invoices"},
      {text_key: "active_subscriptions", text_ar: "اشتراكات فعالة", text_en: "Active subscriptions"},
      {text_key: "expired_subscriptions", text_ar: "اشتراكات منتهية", text_en: "Expired subscriptions"},
      {text_key: "statics", text_ar: "نظرة عامة", text_en: "Statics"},
      {text_key: "mapZones", text_ar: "مواقع  الخريطة", text_en: "Map  Locations"},
      {text_key: "mapzones", text_ar: "مواقع  الخريطة", text_en: "Map  Locations"},
      {text_key: "speedLimit", text_ar: "حدود السرعات", text_en: "Speed Limit"},
      {text_key: "changeRequests", text_ar: "طلبات التعديل", text_en: "Change Requests"},
      {text_key: "comments", text_ar: "التعليقات", text_en: "Comments"},
      {text_key: "published", text_ar: "منشور", text_en: "Published"},
      {text_key: "expired", text_ar: "منتهي", text_en: "Expired"},
      {text_key: "used", text_ar: "مستخدم", text_en: "Used"},
      {text_key: "appPromotions", text_ar: "خصومات التطبيق", text_en: "App Promotions"},
      {text_key: "reports", text_ar: "الابلاغات", text_en: "Reports"},
      {text_key: "promotionsReport", text_ar: "بلاغات العروض", text_en: "Promotions Report"},
      {text_key: "commentsReport", text_ar: "بلاغات التعليقات", text_en: "Comments Report"},
      {text_key: "businessReport", text_ar: "بلاغات المتاجر", text_en: "Business Report"},
      {text_key: "agentsReport", text_ar: "بلاغات الوسطات", text_en: "Agents Report"},
      {text_key: "userReport", text_ar: "بلاغات المستخدمين", text_en: "User Report"},
      {text_key: "chat", text_ar: "المحادثة", text_en: "Chat"},
      {text_key: "usersSubscriptions", text_ar: "إشتراكات المستخدمين", text_en: "Users Subscriptions"},
      {text_key: "businessSubscriptions", text_ar: "إشتركات المتاجر", text_en: "Business Subscriptions"},
      {text_key: "providersSubscriptions", text_ar: "إشتراكات الخدمات", text_en: "Providers Subscriptions"},
      {text_key: "agentsSubscriptions", text_ar: "اشتراكات الوكلاء", text_en: "Agents Subscriptions"},
      {text_key: "invoices", text_ar: "الفواتير", text_en: "Invoices"},
      {text_key: "unpaid", text_ar: "غيرمسدد", text_en: "Unpaid"},
      {text_key: "categories", text_ar: "التصنيفات", text_en: "Categories"},
      {text_key: "subCategories", text_ar: "التصنيفات الفرعية", text_en: "Sub Categories"},
      {text_key: "dataset", text_ar: "اعداد البيانات", text_en: "Dataset"},
      {text_key: "countries", text_ar: "الدول", text_en: "Countries"},
      {text_key: "districts", text_ar: "الاحياء", text_en: "Districts"},
      {text_key: "appSettings", text_ar: "اعدادات التطبيق", text_en: "App Settings"},
      {text_key: "webSettings", text_ar: "إعدادات الموقع", text_en: "Web Settings"},
      {text_key: "mapSettings", text_ar: "إعدادات الخريطة", text_en: "Map Settings"},
      {text_key: "notificationsSettings", text_ar: "", text_en: "Notifications Settings"},
      {text_key: "paymentSettings", text_ar: "إعدادات الدفع", text_en: "Payments Settings"},
      {text_key: "paymentMethods", text_ar: "وسائل الدفع", text_en: "Payment Methods"},
      {text_key: "subPlan", text_ar: "خطط الاشتراك", text_en: "Subscription Plan"},
      {text_key: "usersPlan", text_ar: "باقات المستخدمين", text_en: "Users Plan"},
      {text_key: "adsPlan", text_ar: "باقات الاعلانات", text_en: "Ads Plan"},
      {text_key: "businessPlan", text_ar: "باقات المتاجر", text_en: "Business Plan"},
      {text_key: "providersPlan", text_ar: "باقات مقدمي الخدمات", text_en: "Providers Plan"},
      {text_key: "agentsPlan", text_ar: "باقات الوسطاء العقاريين", text_en: "Agents Plan"},
      {text_key: "logout", text_ar: "تسجيل خروج", text_en: "Logout"},
      {text_key: "profile", text_ar: "حسابي", text_en: "Profile"},
      {text_key: "adsSubscriptions", text_ar: "اشتراكات الاعلان", text_en: "Ads Subscriptions"},
      {text_key: "auctionsSubscriptions", text_ar: "اشتراكات المزادات", text_en: "Auctions Subscriptions"},
      {text_key: "permissions", text_ar: "الصلاحيات", text_en: "Permissions"},
      {text_key: "salesTeam", text_ar: "فريق المبيعات", text_en: "Sales Team"},
      {text_key: "acm", text_ar: "مدير الحسابات", text_en: "Account Mangers"},
      {text_key: "stopped", text_ar: "Stopped", text_en: "Stopped"},
      {text_key: "commissionsCalculator", text_ar: "حساب العمولة", text_en: "Commissions Calculator"},
      {text_key: "knowledgeBaseCategories", text_ar: "أقسام المعرفة", text_en: "Knowledge Categories"},
      {text_key: "knowledgeBase", text_ar: "قاعدة المعرفة", text_en: "Knowledge Base"},
      {text_key: "support", text_ar: "الدعم الفني", text_en: "Support"},
      {text_key: "supportDepartments", text_ar: "أقسام الدعم الفني", text_en: "Support Departments"},
      {text_key: "supportRequests", text_ar: "طلبات الدعم", text_en: "Support Requests"},
      {text_key: "changeRequestsExportImport", text_ar: "تصدير الطلبات", text_en: "Request Export"},
      {text_key: "country", text_ar: "الدولة", text_en: "Country"},
      {text_key: "city", text_ar: "المدينة", text_en: "City"},
      {text_key: "district", text_ar: "الحي", text_en: "District"},
      {text_key: "place_id", text_ar: "معرف الحي", text_en: "Place id"},
      {text_key: "place_code", text_ar: "كود الحي", text_en: "Place code"},
      {text_key: "address", text_ar: "العنوان", text_en: "Address"},
      {text_key: "type", text_ar: "النوع", text_en: "Type"},
      {text_key: "hours", text_ar: "ساعات العمل", text_en: "Hours"},
      {text_key: "website", text_ar: "الموقع الالكتروني", text_en: "Website"},
      {text_key: "latLng", text_ar: "خط الطول والعرض", text_en: "Lat&Lng"},
      {text_key: "contact", text_ar: "الاتصال", text_en: "Contact"},
      {text_key: "rating", text_ar: "التقييم", text_en: "Rating"},
      {text_key: "options", text_ar: "الخيارات", text_en: "Options"},
      {text_key: "street", text_ar: "الشارع", text_en: "Street"},
      {text_key: "streetspeedlimit", text_ar: "السرعة المحددة للشارع", text_en: "Street Speed Limit"},
      {text_key: "streetSpeedLimit", text_ar: "السرعة المحددة للشارع", text_en: "Street Speed Limit"},
      {text_key: "userConfirm", text_ar: "حساب مؤكد", text_en: "User Confirm"},
      {text_key: "owner", text_ar: "صاحب عمل", text_en: "Business Owner"},
      {text_key: "agent", text_ar: "وكيل عقاري", text_en: "Agent"},
      {text_key: "employer", text_ar: "موظف", text_en: "Employer"},
      {text_key: "business_type", text_ar: "نوع العمل", text_en: "Business Type"},
      {text_key: "category", text_ar: "التصنيف", text_en: "Category"},
      {text_key: "description", text_ar: "الوصف", text_en: "Description"},
      {text_key: "attachment", text_ar: "المرفقات", text_en: "Attachment"},
      {text_key: "approved", text_ar: "المراجعة", text_en: "Approved"},
      {text_key: "salesMan", text_ar: "رجل المبيعات", text_en: "Sales Man"},
      {text_key: "approvedBy", text_ar: "مراجع بواسطة", text_en: "Approved By"},
      {text_key: "branchManager", text_ar: "مدير الفرع", text_en: "Branch Manager"},
      {text_key: "branchLocation", text_ar: "موقع الفرع", text_en: "Branch Location"},
      {text_key: "branches", text_ar: "الفروع", text_en: "Branches"},
      {text_key: "branchContact", text_ar: "", text_en: "Branch Contact"},
      {text_key: "workingHours", text_ar: "", text_en: "Working hours"},
      {text_key: "mediaGallery", text_ar: "", text_en: "Media Gallery"},
      {text_key: "employers", text_ar: "", text_en: "Employers"},
      {text_key: "employersRole", text_ar: "", text_en: "Employer Role"},
      {text_key: "employerRolePermissions", text_ar: "", text_en: "Employer  Permissions"},
      {text_key: "employerLog", text_ar: "", text_en: "Employers Log"},
      {text_key: "productsServices", text_ar: "", text_en: "Products Services"},
      {text_key: "wordsFilter", text_ar: "", text_en: "Words Filter"},
      {text_key: "businessServices", text_ar: "", text_en: "Business Services"},
      {text_key: "auctionPlan", text_ar: "", text_en: "Auction Plan"},
      {text_key: "speedLimitOpt", text_ar: "", text_en: "SpeedLimit Options"},
      {text_key: "auctionsPlan", text_ar: "", text_en: "Auctions Plan"},
      {text_key: "mapZonesCat", text_ar: "", text_en: "Map Locations Cat"},
      {text_key: "search_in_map", text_ar: "", text_en: "Search in Map"},
      {text_key: "details_ar", text_ar: "", text_en: "Details AR"},
      {text_key: "details_en", text_ar: "", text_en: "Details EN"},
      {text_key: "methodType", text_ar: "", text_en: "Method type"},
      {text_key: "word", text_ar: "", text_en: "Word"},
      {text_key: "wordParts", text_ar: "", text_en: "Word parts  "},
      {text_key: "price_range", text_ar: "", text_en: "Price range"},
      {text_key: "onSearch", text_ar: "", text_en: "View on Search"},
      {text_key: "duration", text_ar: "", text_en: "Duration (days)"},
      {text_key: "topAds", text_ar: "", text_en: "Top view count"},
      {text_key: "topViews", text_ar: "", text_en: "top views"},
      {text_key: "totalViews", text_ar: "", text_en: "Top views count "},
      {text_key: "adsOnSearch", text_ar: "", text_en: "Ads on Search"},
      {text_key: "following", text_ar: "", text_en: "Following"},
      {text_key: "favorites", text_ar: "", text_en: "Favorites"},
      {text_key: "noOfAds", text_ar: "", text_en: "No of Ads"},
      {text_key: "products", text_ar: "", text_en: "No of products"},
      {text_key: "employersLog", text_ar: "", text_en: "Employers Log"},
      {text_key: "featured", text_ar: "", text_en: "Featured"},
      {text_key: "whatsappBtn", text_ar: "", text_en: "Whatsapp Button"},
      {text_key: "callBtn", text_ar: "", text_en: "Call Button"},
      {text_key: "chatBtn", text_ar: "", text_en: "Chat Button"},
      {text_key: "radius", text_ar: "", text_en: "Radius"},
      {text_key: "matching", text_ar: "", text_en: "Matching"},
      {text_key: "defaultLang", text_ar: "", text_en: "Default Language"},
      {text_key: "loginWithSocialMedia", text_ar: "", text_en: "Login with social media"},
      {text_key: "allowSignup", text_ar: "", text_en: "Allow Signup"},
      {text_key: "currentSpeedShow", text_ar: "", text_en: "Current Speed show"},
      {text_key: "streetSpeedShow", text_ar: "", text_en: "Street Speed show"},
      {text_key: "allowFavorites", text_ar: "", text_en: "Allow Favorites"},
      {text_key: "allowDarkMode", text_ar: "", text_en: "Allow Dark Mode"},
      {text_key: "allowChat", text_ar: "", text_en: "Allow Chat"},
      {text_key: "allowImportedLocation", text_ar: "", text_en: "Allow imported location"},
      {text_key: "allowDataBackup", text_ar: "", text_en: "Allow data backup"},
      {text_key: "allowVoiceSearch", text_ar: "", text_en: "Allow voice search"},
      {text_key: "allowWhatsAppIcon", text_ar: "", text_en: "Allow WhatsApp icon"},
      {text_key: "allowCallIcon", text_ar: "", text_en: "Allow Call icon"},
      {text_key: "allowSearchHistory", text_ar: "", text_en: "Allow search history"},
      {text_key: "allowTimelineTracking", text_ar: "", text_en: "Allow Time line Tracking"},
      {text_key: "uploadVideos", text_ar: "", text_en: "Upload videos"},
      {text_key: "streetSpeedChangeRequests", text_ar: "", text_en: "Street Speed Change Requests"},
      {text_key: "locationsUpdatesRequests", text_ar: "", text_en: "Locations Updates  Requests"},
      {text_key: "timelineTrackingDays", text_ar: "", text_en: "Time line tracking days"},
      {text_key: "googleToken", text_ar: "", text_en: "Google Token"},
      {text_key: "faceBookToken", text_ar: "", text_en: "Facebook Token"},
      {text_key: "appleToken", text_ar: "", text_en: "Apple Token"},
      {text_key: "mapKey", text_ar: "", text_en: "Map Key"},
      {text_key: "navigator", text_ar: "", text_en: "Navigator"},
      {text_key: "appSdk", text_ar: "", text_en: "Sdk for App"},
      {text_key: "webSdk", text_ar: "", text_en: "Sdk for Web"},
      {text_key: "placesKey", text_ar: "", text_en: "Places find key"},
      {text_key: "directionsKey", text_ar: "", text_en: "Navigation Directions key"},
      {text_key: "offersAlertRadius", text_ar: "", text_en: "Alert offers radius"},
      {text_key: "shareLocation", text_ar: "", text_en: "Share Location"},
      {text_key: "shareLiveLocation", text_ar: "", text_en: "Share Live Location"},
      {text_key: "offersPin", text_ar: "", text_en: "Offers Pin"},
      {text_key: "offersPinClicked", text_ar: "", text_en: "Offers clicked Pin"},
      {text_key: "providersPinClicked", text_ar: "", text_en: "Providers clicked Pin"},
      {text_key: "requestsPinClicked", text_ar: "", text_en: "Requests clicked Pin"},
      {text_key: "auctionsPinClicked", text_ar: "", text_en: "Auctions clicked Pin"},
      {text_key: "propertiesPinClicked", text_ar: "", text_en: "Properties clicked Pin"},
      {text_key: "openedColor", text_ar: "", text_en: "Opened Color"},
      {text_key: "closedColor", text_ar: "", text_en: "Closed Color"},
      {text_key: "closedSoonColor", text_ar: "", text_en: "Closed soon Color"},
      {text_key: "senderID", text_ar: "", text_en: "Sender ID"},
      {text_key: "serverKey", text_ar: "", text_en: "Server key"},
      {text_key: "notificationSettings", text_ar: "", text_en: "Notification Settings"},
      {text_key: "defaultPaymentMethod", text_ar: "", text_en: "Default Payment Method"},
      {text_key: "paymentsSettings", text_ar: "", text_en: "Payments Settings"},
      {text_key: "financePeriodDays", text_ar: "", text_en: "Finance period days"},
      {text_key: "tax", text_ar: "", text_en: "TAX"},
      {text_key: "vat", text_ar: "", text_en: "VAT"},
      {text_key: "categoryType", text_ar: "", text_en: "Category Type"},
      {text_key: "subCategory", text_ar: "", text_en: "Sub Category"},
      {text_key: "lat", text_ar: "", text_en: "Lat"},
      {text_key: "lng", text_ar: "", text_en: "Lng"},
      {text_key: "phone", text_ar: "", text_en: "Phone"},
      {text_key: "branch", text_ar: "", text_en: "Branch"},
      {text_key: "whatsapp", text_ar: "", text_en: "Whatsapp"},
      {text_key: "fileType", text_ar: "", text_en: "File Type"},
      {text_key: "file", text_ar: "", text_en: "File"},
      {text_key: "user", text_ar: "", text_en: "User"},
      {text_key: "role", text_ar: "", text_en: "Role"},
      {text_key: "knowledgeCat", text_ar: "", text_en: "Knowledge Category"},
      {text_key: "streetSpeedLimitRequests", text_ar: "", text_en: "Street Speed Limit Requests"},
      {text_key: "streetspeedlimitrequests", text_ar: "", text_en: "Street Speed Limit Requests"},
      {text_key: "birthDate", text_ar: "", text_en: "Birth Date"},
      {text_key: "store", text_ar: "", text_en: "Store"},
      {text_key: "provider", text_ar: "", text_en: "Provider"},
      {text_key: "BusinessMeta", text_ar: "", text_en: "Business Meta"},
      {text_key: "usageTimes", text_ar: "", text_en: "Usage Times"},
      {text_key: "promotionFor", text_ar: "", text_en: "Promotion For"},
      {text_key: "startDate", text_ar: "", text_en: "End Date"},
      {text_key: "endDate", text_ar: "", text_en: "End Date"},
      {text_key: "promotionCode", text_ar: "", text_en: "Promotion Code"},
      {text_key: "discountValue", text_ar: "", text_en: "DiscountValue "},
      {text_key: "deviceOs", text_ar: "", text_en: "Device OS"},
      {text_key: "deviceInfo", text_ar: "", text_en: "Device Info"},
      {text_key: "day", text_ar: "", text_en: "Day"},
      {text_key: "fromTime", text_ar: "", text_en: "From Time"},
      {text_key: "toTime", text_ar: "", text_en: "To Time"},
      {text_key: "dayOff", text_ar: "", text_en: "Day off"},
      {text_key: "", text_ar: "", text_en: ""},
      {text_key: "", text_ar: "", text_en: ""},
      {text_key: "", text_ar: "", text_en: ""},
      {text_key: "", text_ar: "", text_en: ""},
      {text_key: "", text_ar: "", text_en: ""},
      {text_key: "", text_ar: "", text_en: ""},
      {text_key: "", text_ar: "", text_en: ""},
      {text_key: "", text_ar: "", text_en: ""},
      {text_key: "", text_ar: "", text_en: ""},
      {text_key: "", text_ar: "", text_en: ""},
      {text_key: "", text_ar: "", text_en: ""},
      ];
//
  }

}

