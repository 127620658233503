<!-- ========== Left Sidebar Start ========== -->


<ng-template #contentTemplate>

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>


      <li class="menu-title">{{ GetWordText('mapZones') }}</li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-map-pin"></i>
        <span>  {{ GetWordText('mapZones') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/mapZones/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/mapZones/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/mapZones/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/mapZones/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>

      <li class="menu-title">{{ GetWordText('speedLimit') }}</li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-road"></i>
        <span>  {{ GetWordText('speedLimit') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">

          <li><a class="side-nav-link-ref" routerLink="/streetSpeedLimit/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/streetSpeedLimit/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/streetSpeedLimit/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/streetSpeedLimit/exportImport"> {{ GetWordText('exportImport') }} </a></li>

          <li><a class="side-nav-link-ref" routerLink="/streetSpeedLimit/changeRequests"> {{ GetWordText('changeRequests') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/streetSpeedLimit/changeRequestsExportImport"> {{ GetWordText('changeRequestsExportImport') }} </a></li>
        </ul>
      </li>
      <li class="menu-title">{{ GetWordText('users') }}</li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-user"></i>
        <span>  {{ GetWordText('users') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/users/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/users/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/users/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/users/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>

      <li class="menu-title">{{ GetWordText('business') }}</li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-store"></i>
        <span>  {{ GetWordText('business') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/business/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/business/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/business/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/business/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-user-badge"></i>
        <span>  {{ GetWordText('providers') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/providers/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/providers/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/providers/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/providers/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-home-city"></i>
        <span>  {{ GetWordText('agents') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/agents/requests"> {{ GetWordText('requests') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/agents/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/agents/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/agents/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/agents/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>

      <li class="menu-title">{{ GetWordText('BusinessMeta') }}</li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-map-marker-circle"></i>
        <span>  {{ GetWordText('branches') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/branches/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/branches/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/branches/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/branches/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-email-variant"></i>
        <span>  {{ GetWordText('branchContact') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/branchContact/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/branchContact/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/branchContact/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/branchContact/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-clock"></i>
        <span>  {{ GetWordText('workingHours') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/workingHours/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/workingHours/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/workingHours/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/workingHours/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li> <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-picture-in-picture-bottom-right"></i>
        <span>  {{ GetWordText('mediaGallery') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/mediaGallery/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/mediaGallery/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/mediaGallery/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/mediaGallery/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-face-agent"></i>
        <span>  {{ GetWordText('employers') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/employers/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/employers/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/employers/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/employers/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-account-key"></i>
        <span>  {{ GetWordText('employersRole') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/employersRole/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/employersRole/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/employersRole/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/employersRole/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-account-key"></i>
        <span>  {{ GetWordText('employerRolePermissions') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/employerRolePermissions/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/employerRolePermissions/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/employerRolePermissions/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/employerRolePermissions/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-phone-log"></i>
        <span>  {{ GetWordText('employerLog') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/employerLog/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/employerLog/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/employerLog/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-cube-scan"></i>
        <span>  {{ GetWordText('productsServices') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/productsServices/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/productsServices/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/productsServices/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>

      <li class="menu-title">{{ GetWordText('properties') }}</li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-bell-plus"></i>
        <span>  {{ GetWordText('auctions') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/auctions/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/auctions/filter/today"> {{ GetWordText('today') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/auctions/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/auctions/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/auctions/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-home-group"></i>
        <span>  {{ GetWordText('properties') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/properties/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/properties/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/properties/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/properties/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-database-search"></i>
        <span>  {{ GetWordText('requests') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/userRequests/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/userRequests/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/userRequests/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/userRequests/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>
      <li class="menu-title">{{ GetWordText('promotions') }}</li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-offer"></i>
        <span>  {{ GetWordText('promotions') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/promotions"> {{ GetWordText('statics') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/promotions/filter/active"> {{ GetWordText('active') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/promotions/filter/expired"> {{ GetWordText('expired') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/promotions/filter/used"> {{ GetWordText('used') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/promotions/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/promotions/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/promotions/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>
      <li class="menu-title">{{ GetWordText('comments') }}</li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-comment"></i>
        <span>  {{ GetWordText('comments') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/comments/filter/waiting"> {{ GetWordText('waiting') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/comments/filter/published"> {{ GetWordText('published') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/comments/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/users/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>
      <li class="menu-title">{{ GetWordText('reports') }}</li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-flag"></i>
        <span>  {{ GetWordText('promotionsReport') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/promotionsReport/filter/new"> {{ GetWordText('new') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/promotionsReport/filter/viewed"> {{ GetWordText('viewed') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/promotionsReport/filter/critical"> {{ GetWordText('critical') }} </a>
          </li>
          <li><a class="side-nav-link-ref"
                 routerLink="/promotionsReport/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-flag"></i>
        <span>  {{ GetWordText('commentsReport') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/commentsReport/filter/new"> {{ GetWordText('new') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/commentsReport/filter/viewed"> {{ GetWordText('viewed') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/commentsReport/filter/critical"> {{ GetWordText('critical') }} </a>
          </li>
          <li><a class="side-nav-link-ref"
                 routerLink="/commentsReport/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-flag"></i>
        <span>  {{ GetWordText('businessReport') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/storeReport/filter/new"> {{ GetWordText('new') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/storeReport/filter/viewed"> {{ GetWordText('viewed') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/storeReport/filter/critical"> {{ GetWordText('critical') }} </a>
          </li>
          <li><a class="side-nav-link-ref"
                 routerLink="/storeReport/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-flag"></i>
        <span>  {{ GetWordText('agentsReport') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/agentsReport/filter/new"> {{ GetWordText('new') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/agentsReport/filter/viewed"> {{ GetWordText('viewed') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/agentsReport/filter/critical"> {{ GetWordText('critical') }} </a></li>
          <li><a class="side-nav-link-ref"
                 routerLink="/agentsReport/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-flag"></i>
        <span>  {{ GetWordText('userReport') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/userReport/filter/new"> {{ GetWordText('new') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/userReport/filter/viewed"> {{ GetWordText('viewed') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/userReport/filter/critical"> {{ GetWordText('critical') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/userReport/filter/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>

      <li class="menu-title">{{ GetWordText('chat') }}</li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-chat"></i>
        <span>  {{ GetWordText('chat') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/users/search"> {{ GetWordText('search') }} </a></li>
        </ul>
      </li>
      <li class="menu-title">{{ GetWordText('finance') }}</li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-discount"></i>
        <span>  {{ GetWordText('appPromotions') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/appPromotions/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/appPromotions/filter/active"> {{ GetWordText('active') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/appPromotions/filter/expired"> {{ GetWordText('expired') }} </a></li>
          <li><a class="side-nav-link-ref"
                 routerLink="/appPromotions/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-dollar-circle"></i>
        <span>  {{ GetWordText('adsSubscriptions') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/adsSubscriptions/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/adsSubscriptions/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/adsSubscriptions/filter/active"> {{ GetWordText('active') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/adsSubscriptions/filter/expired"> {{ GetWordText('expired') }} </a>
          </li>
        </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-dollar-circle"></i>
        <span>  {{ GetWordText('auctionsSubscriptions') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/auctionsSubscriptions/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/auctionsSubscriptions/all"> {{ GetWordText('all') }} </a>
          <li><a class="side-nav-link-ref" routerLink="/auctionsSubscriptions/deleted"> {{ GetWordText('deleted') }} </a>
          </li>
          <li><a class="side-nav-link-ref" routerLink="/auctionsSubscriptions/filter/active"> {{ GetWordText('active') }} </a>
          </li>
          <li><a class="side-nav-link-ref"
                 routerLink="/auctionsSubscriptions/filter/expired"> {{ GetWordText('expired') }} </a></li>
        </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-dollar-circle"></i>
        <span>  {{ GetWordText('usersSubscriptions') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/usersSubscriptions/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/usersSubscriptions/all"> {{ GetWordText('all') }} </a>
          <li><a class="side-nav-link-ref" routerLink="/usersSubscriptions/deleted"> {{ GetWordText('deleted') }} </a>
          </li>
          <li><a class="side-nav-link-ref" routerLink="/usersSubscriptions/filter/active"> {{ GetWordText('active') }} </a>
          </li>
          <li><a class="side-nav-link-ref" routerLink="/usersSubscriptions/filter/expired"> {{ GetWordText('expired') }} </a>
          </li>
        </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-dollar-circle"></i>
        <span>  {{ GetWordText('businessSubscriptions') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/businessSubscriptions/add"> {{ GetWordText('add') }} </a>
          <li><a class="side-nav-link-ref" routerLink="/businessSubscriptions/all"> {{ GetWordText('all') }} </a>
          <li><a class="side-nav-link-ref" routerLink="/businessSubscriptions/deleted"> {{ GetWordText('deleted') }} </a>
          </li>
          <li><a class="side-nav-link-ref" routerLink="/businessSubscriptions/filter/active"> {{ GetWordText('active') }} </a>
          </li>
          <li><a class="side-nav-link-ref"
                 routerLink="/businessSubscriptions/filter/expired"> {{ GetWordText('expired') }} </a></li>
        </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-dollar-circle"></i>
        <span>  {{ GetWordText('providersSubscriptions') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/providersSubscriptions/add"> {{ GetWordText('add') }} </a>
          <li><a class="side-nav-link-ref" routerLink="/providersSubscriptions/all"> {{ GetWordText('all') }} </a>
          <li><a class="side-nav-link-ref" routerLink="/providersSubscriptions/deleted"> {{ GetWordText('deleted') }} </a>
          </li>
          <li><a class="side-nav-link-ref" routerLink="/providersSubscriptions/filter/active"> {{ GetWordText('active') }} </a>
          </li>
          <li><a class="side-nav-link-ref"
                 routerLink="/providersSubscriptions/filter/expired"> {{ GetWordText('expired') }} </a></li>
        </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-dollar-circle"></i>
        <span>  {{ GetWordText('agentsSubscriptions') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/agentsSubscriptions/add"> {{ GetWordText('add') }} </a>
          <li><a class="side-nav-link-ref" routerLink="/agentsSubscriptions/all"> {{ GetWordText('all') }} </a>
          <li><a class="side-nav-link-ref" routerLink="/agentsSubscriptions/deleted"> {{ GetWordText('deleted') }} </a>
          </li>
          <li><a class="side-nav-link-ref" routerLink="/agentsSubscriptions/filter/active"> {{ GetWordText('active') }} </a>
          </li>
          <li><a class="side-nav-link-ref" routerLink="/agentsSubscriptions/filter/expired"> {{ GetWordText('expired') }} </a>
          </li>
        </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-dollar-circle"></i>
        <span>  {{ GetWordText('invoices') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/invoices/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/invoices/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/invoices/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/invoices/filter/paid"> {{ GetWordText('paid') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/invoices/filter/unpaid"> {{ GetWordText('unpaid') }} </a></li>
        </ul>
      </li>


      <li class="menu-title">{{ GetWordText('support') }}</li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-help"></i>
        <span>  {{ GetWordText('supportRequests') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/support/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/support/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/support/filter/opened"> {{ GetWordText('opened') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/support/filter/waitingReplay"> {{ GetWordText('waitingReplay') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/support/filter/closed"> {{ GetWordText('closed') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/support/filter/opened"> {{ GetWordText('opened') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/support/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/categories/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-help-box"></i>
        <span>  {{ GetWordText('supportDepartments') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/supportDepartments/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/supportDepartments/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/supportDepartments/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/supportDepartments/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>

      <li class="menu-title">{{ GetWordText('dataset') }}</li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-map-marker-multiple"></i>
        <span>  {{ GetWordText('mapZonesCat') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/mapZonesCat/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/mapZonesCat/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/mapZonesCat/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/mapZonesCat/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-tag"></i>
        <span>  {{ GetWordText('categories') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/category/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/category/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/category/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/category/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-tag"></i>
        <span>  {{ GetWordText('subCategories') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/subCategory/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/subCategory/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/subCategory/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref"
                 routerLink="/subCategory/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false"><i
          class="bx mdi-24px bxs-map"></i><span>  {{ GetWordText('countries') }}  </span> </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/countries/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/countries"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/countries/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/countries/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false"><i
          class="bx mdi-24px bxs-map-alt"></i><span>  {{ GetWordText('cities') }}  </span> </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/cities/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/cities"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/cities/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/cities/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false"><i
          class="bx mdi-24px bxs-map-pin"></i><span>  {{ GetWordText('districts') }}  </span> </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/districts/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/districts"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/districts/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/districts/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>

      <li>
        <a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
          <i class="bx mdi-24px bxs-credit-card"></i>
          <span>  {{ GetWordText('paymentMethods') }}  </span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/paymentMethods/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/paymentMethods/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/paymentMethods/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref"
                 routerLink="/paymentMethods/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
          <i class="bx mdi-24px bxs-filter-alt"></i>
          <span>  {{ GetWordText('wordsFilter') }}  </span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/wordsFilter/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/wordsFilter/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/wordsFilter/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref"
                 routerLink="/wordsFilter/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li> <li>
        <a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
          <i class="bx mdi-24px bxs-building"></i>
          <span>  {{ GetWordText('businessServices') }}  </span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/businessServices/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/businessServices/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/businessServices/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref"
                 routerLink="/businessServices/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>
<li>
        <a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
          <i class="bx mdi-24px bxs-filter-alt"></i>
          <span>  {{ GetWordText('speedLimitOpt') }}  </span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/speedLimitOpt/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/speedLimitOpt/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/speedLimitOpt/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref"
                 routerLink="/speedLimitOpt/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>


      <li class="menu-title">{{ GetWordText('subPlan') }}</li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-cuboid"></i>
        <span>  {{ GetWordText('adsPlan') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/adsPlan/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/adsPlan/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/adsPlan/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/adsPlan/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-cuboid"></i>
        <span>  {{ GetWordText('auctionsPlan') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/auctionPlan/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/auctionPlan/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/auctionPlan/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/auctionPlan/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-user-detail"></i>
        <span>  {{ GetWordText('usersPlan') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/usersPlan/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/usersPlan/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/usersPlan/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/usersPlan/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-building"></i>
        <span>  {{ GetWordText('businessPlan') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/businessPlan/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/businessPlan/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/businessPlan/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/businessPlan/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-user-check"></i>
        <span>  {{ GetWordText('providersPlan') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/providersPlan/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/providersPlan/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/providersPlan/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/providersPlan/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-user-badge"></i>
        <span>  {{ GetWordText('agentsPlan') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/agentsPlan/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/agentsPlan/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/agentsPlan/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/agentsPlan/exportImport"> {{ GetWordText('exportImport') }} </a>
          </li>
        </ul>
      </li>


      <li class="menu-title">{{ GetWordText('notifications') }}</li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-notification"></i>
        <span>  {{ GetWordText('notifications') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/notifications/add"> {{ GetWordText('send') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/notifications/all"> {{ GetWordText('history') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/notifications/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>

      <li class="menu-title">{{ GetWordText('admins') }}</li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-user"></i>
        <span>  {{ GetWordText('admins') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/admins/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/admins/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/admins/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/admins/filter/blocked"> {{ GetWordText('blocked') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/admins/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-key"></i>
        <span>  {{ GetWordText('admins_level') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/admins_level/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/admins_level/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/admins_level/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/admins_level/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-user-x"></i>
        <span>  {{ GetWordText('permissions') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/admins_access/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/admins_access/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/admins_access/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/admins_access/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-user-x"></i>
        <span>  {{ GetWordText('adminslog') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/adminslog/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/adminslog/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-user-voice"></i>
        <span>  {{ GetWordText('salesTeam') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/salesMan/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/salesMan/filter/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/salesMan/filter/stopped"> {{ GetWordText('stopped') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/salesMan/filter/commissionsCalculator"> {{ GetWordText('commissionsCalculator') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/salesMan/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-user-plus"></i>
        <span>  {{ GetWordText('acm') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/acm/filter/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/acm/filter/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/acm/filter/all"> {{ GetWordText('stopped') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/acm/filter/all"> {{ GetWordText('commissionsCalculator') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/acm/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>


      <li class="menu-title">{{ GetWordText('settings') }}</li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-apps"></i>
        <span>  {{ GetWordText('appSettings') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/appSettings/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/appSettings/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/appSettings/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/appSettings/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-cogs"></i>
        <span>  {{ GetWordText('webSettings') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/webSettings/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/webSettings/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/webSettings/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/webSettings/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>



      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-cog-sync"></i>
        <span>  {{ GetWordText('mapSettings') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/mapSettings/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/mapSettings/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/mapSettings/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/mapSettings/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>

      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-application-cog"></i>
        <span>  {{ GetWordText('notificationsSettings') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/notificationsSettings/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/notificationsSettings/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/notificationsSettings/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/notificationsSettings/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>


      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="mdi mdi-24px mdi-application-cog"></i>
        <span>  {{ GetWordText('paymentsSettings') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/paymentsSettings/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/paymentsSettings/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/paymentsSettings/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/paymentsSettings/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>

      <li class="menu-title">{{ GetWordText('knowledgeBase') }}</li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-book-bookmark"></i>
        <span>  {{ GetWordText('knowledgeBase') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/knowledge/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/knowledge/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/knowledge/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/knowledge/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>
      <li><a class="is-parent has-arrow" href="javascript:void()" aria-expanded="false">
        <i class="bx mdi-24px bxs-tag-x"></i>
        <span>  {{ GetWordText('knowledgeBaseCategories') }}  </span>
      </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><a class="side-nav-link-ref" routerLink="/knowledgeCat/add"> {{ GetWordText('add') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/knowledgeCat/all"> {{ GetWordText('all') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/knowledgeCat/deleted"> {{ GetWordText('deleted') }} </a></li>
          <li><a class="side-nav-link-ref" routerLink="/knowledgeCat/exportImport"> {{ GetWordText('exportImport') }} </a></li>
        </ul>
      </li>


    </ul>


  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->
